<template>
    <div>
        <h1>Events</h1>

        {{ val }}

        <notification :element="notification" />
        <notification :element="notification2" />

        <div @click="prompt">Prompt</div>

        <button @click="add">Add</button>
    </div>
</template>

<script>
import Notification from "@Platon/components/extended/Notification"

export default {
    name: "TestEventsPage",
    components: { Notification },
    data() {
        return {
            val: 1,

            notification: {
                show: true,
                title: "A",
                description: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla at suscipit tellus, non tincidunt lorem. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Sed volutpat ligula in est tempor, condimentum dictum velit gravida. Donec egestas lobortis mi, ut vehicula quam congue a. Sed nec tortor imperdiet, tristique sapien eget, laoreet orci. Cras vehicula porttitor scelerisque. Praesent vitae ipsum molestie, luctus nibh quis, ultrices metus. Sed pretium neque augue, rhoncus malesuada quam varius sed. Sed ultrices lacus mollis mauris rhoncus, sed feugiat ex laoreet. Sed gravida magna nunc, sed efficitur nisl gravida ut. Nulla facilisi. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer erat velit, dictum vel lobortis sollicitudin, dignissim ut orci. Maecenas facilisis rhoncus dignissim. Sed hendrerit eu magna luctus vestibulum. Sed sollicitudin in sem eu rhoncus.

Donec erat est, pharetra non magna ut, porta commodo neque. Proin accumsan ut lorem non ultricies. Maecenas at vestibulum eros. Phasellus suscipit lacus et ligula laoreet, eget iaculis metus laoreet. Donec interdum est ligula, ac varius lectus venenatis vitae. Nulla id gravida dolor. Proin non vulputate augue.

Cras vehicula congue nisi, vitae pulvinar felis blandit quis. Vestibulum scelerisque erat lectus, quis fringilla turpis faucibus eget. Curabitur non dictum urna, ac volutpat sem. Pellentesque ullamcorper sem urna, ut mattis tellus placerat et. Vivamus a tincidunt justo, id finibus dolor. Morbi in nulla ac est eleifend eleifend. Morbi ut tortor vel purus posuere tincidunt ac in purus. Donec venenatis nisl ut posuere auctor. Sed mattis mollis tempus. Donec dolor mauris, gravida vel mollis et, vestibulum vel felis. Ut ullamcorper feugiat ipsum, sit amet pulvinar ipsum imperdiet non. Sed nec sapien consequat, pulvinar eros posuere, viverra nulla. Nunc magna nunc, mollis sed blandit nec, elementum ut lectus. Nulla faucibus felis commodo nunc lacinia imperdiet.
`,
                type: "warning",
                dismissible: true,
                actions: [
                    {
                        type: "danger",
                        label: "Test",
                        event() {
                            console.log("a")
                        }
                    }
                ]
            },

            notification2: {
                show: true,
                component: {
                    template: `<div>Hello</div>`
                },
                type: "warning",
                dismissible: true,
                actions: [
                    {
                        type: "danger",
                        label: "Test",
                        event() {
                            console.log("a")
                        }
                    }
                ]
            }
        }
    },

    mounted() {
        this.$onEvent("add", (a, b, c) => {
            this.val += a + b + c
        })
    },
    methods: {
        add() {
            this.$sendEvent("add", 1, 2, 3)
            this.$sendEvent("daa", 1, 2, 3)
        },

        prompt() {
            this.promptAction("Title", null, {
                rejectText: "Reject text",
                confirmText: "Confirm text",

                component: {
                    data() {
                        return {
                            inputVal: null
                        }
                    },

                    template: `<select class="form-control" v-model="inputVal">
					<option value="A">A</option>
					<option value="B">B</option>
					<option value="C">C</option>
					</select>`
                }
            }).then((res) => {
                alert(res)
            })
        }
    }
}
</script>
